.WalkthroughPage__main-content {
        flex-grow: 1; 
        transition: margin-left 0.3s ease; 
        padding: 20px;
        padding-right: 100px;
        margin-left: 80px;
        align-content: center;
        color: var(--font-color, #090e16);
        height: 95vh;
        overflow-y: auto;
}

.WalkthroughPage__views-text {
        padding-top: 30px;
}

.WalkthroughPage__page-break {
        page-break-before: always;
}    

.WalkthroughPage__html-h1 {
        padding-right: 20px;
}

.WalkthroughPage__icon-padding {
        padding-right: 8px;
        /* padding-left: 5px; */
        font-size: 21px;
        margin-bottom: 50px;
}

.WalkthroughPage__author-name {
        margin-top: 50px;
}

.WalkthroughPage__tutorial-cover {
        margin-top: 100px;
}

.WalkthroughPage__prev-button,
.WalkthroughPage__next-button {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        padding: 10px 15px;
        padding-top: 12px;
        background-color: var(--font-color, #090e16);
        color: var(--font-color-icons, white);
        border: none;
        border-radius: 2px;
        cursor: pointer;
        z-index: 100;
        transition: left 0.3s ease;
}

.WalkthroughPage__prev-button {
        left: 260px;
}

.WalkthroughPage__popup-list {
        margin-left: 10px;
}

.WalkthroughPage__next-button {
        right: 20px;
}

.highlighted-text {
        background-color: #ffeb3b;
        cursor: pointer;
}    

/* Add this to your CSS */
.WalkthroughPage__highlight-icon-glow {
        color: var(--font-color-icons);
        text-shadow:     
        0 0 10px rgba(255, 215, 0, 0.8),
        0 0 20px rgba(255, 215, 0, 0.6);
        animation: pulse-glow 3s infinite;
}
      
@keyframes pulse-glow {
        0% {
          text-shadow: 
            0 0 10px rgba(255, 215, 0, 1.5),
            0 0 20px rgba(255, 215, 0, 1.2);
        }
        50% {
          text-shadow: 
            0 0 15px rgba(255, 215, 0, 4),
            0 0 30px rgba(255, 215, 0, 3);
        }
        100% {
          text-shadow: 
            0 0 10px rgba(255, 215, 0, 1.5),
            0 0 20px rgba(255, 215, 0, 1.2);
        }
}

@media (max-width: 600px) {
        .WalkthroughPage__main-content {
                padding-top: 12vh;
                margin-left: 0;
                padding-right: 110px;
                overflow-x: hidden;
        }
}

/* Add these styles to your WalkthroughPage.css file */
.WalkthroughPage__print-button {
  background-color: var(--font-color, #090e16);
  color: var(--font-color-icons, white);
  border: none;
  border-radius: 3px;
  padding: 5px 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.WalkthroughPage__print-button:hover {
  background-color: var(--btn-color, #5B92E5);
}

.WalkthroughPage__print-button i {
  margin-right: 5px;
}

@media print {
  .Document__floating-div,
  .WalkthroughPage__prev-button,
  .WalkthroughPage__next-button {
    display: none !important;
  }
}
