.Auth__text-center{
        color:#fff;	
        text-transform:uppercase;
        margin: -50px 0 80px 0;
        display: block;
        text-align: center;
}

.Auth__text {
        color: white;
}

.Auth__box {
        background-color: #090E16;
        border-radius:3px;
        padding:70px 100px;
        width: 45%;
}

.Auth__input-container{
	position:relative;
	margin-bottom:25px;
}

.Auth__input {
        border-radius: 0;
        /* background: transparent; */
        border: none;         
        border-bottom: 1px solid white; 
        color: #090E16;     
        padding-left: 20px;
        padding-right: 0;
        padding-bottom: 5px;
        padding-top: 5px;
        outline: none;
        font-size: 20px;
        margin-top: 30px;
}

.Auth__input::placeholder {
        color: white;
        transition: color 0.3s ease;
}

.Auth__input:focus::placeholder {
        color: transparent;
}

.Auth__input:focus {
        outline: none;
        border: 0;
        border-bottom: 1px solid white;
}

.Auth__input-container label{
        position:absolute;
        top: 0px;
        left: 0px;
        font-size:16px;
        color:#fff;	
        pointer-events:none;
        transition: all 0.2s ease-in-out;
}

.Auth__input-container input{ 
        border:0;
        border-bottom:1px solid #555;  
        background:transparent;
        width:100%;
        padding:8px 0 5px 0;
        font-size:16px;
        color:#fff;
}

.Auth__input-container input:focus{ 
        border:none;	
        outline:none;
        border-bottom:1px solid #6b9ce7;	
}

.Auth__btn{
        color:#090E16;
        background-color: #6b9ce7;
        outline: none;
        border: 0;
        padding:10px 20px;
        text-transform:uppercase;
        margin-top:50px;
        border-radius:2px;
        cursor:pointer;
        position:relative;
}

.Auth__input-container input:focus ~ label,
.Auth__input-container input:valid ~ label{
        top: -12px;
        font-size: 12px;
}

.notification {
        background-color: #090E16;
        position: fixed;
        width: 40%;
        top: -900px;
        left: 0;
        right: 0;
        padding: 10px;
        text-align: center;
        text-overflow: ellipsis;
        border-radius: 5px;
        color: white;
        margin: auto;
}

@media (max-width: 1150px) {
        .Auth__box {
                width: 60%
        }
}

@media (max-width: 600px) {
        .Auth__box {
                width: 100%;
                height: 100%;
        }
}

.animated {
        animation: slide-down 0.5s forwards;
    }
    
    @keyframes slide-down {
        from {
            top: -900px;
        }
        to {
            top: 20px; /* Visible position */
        }
    }
    