.ConflictResolver__button {
        background-color: var(--bg-button, #090e16);
        border: 0;
        outline: none;
        padding: 8px;
        border-radius: 2px;
        color: var(--btn-color, white);
}

.ConflictResolver__container {
        background-color: var(--bg-conflict-resolver, #DEE9F9);
        padding: 20px;
        margin-bottom: 20px;
        color: var(--conflict-resolver-color, #090e16);
        border-radius: 5px;
}

@media (max-width: 600px) {

        .ConflictResolver__button {
                margin-top: 10px;
                width: 100%;
        }

}