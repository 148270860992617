.EditDocument__edit-document-container {
        display: flex;
        gap: 10px;
    }
    
    .EditDocument__editor-section {
        flex: 1;
    }
    
    .EditDocument__editor-section.split {
        flex: 0.5; /* Reduce width when split-view is active */
    }
    
    .EditDocument__view-document-section {
        flex: 0.5;
        padding-left: 10px;
        overflow-y: auto;
        max-height: 75vh;
        background: var(--bg-side-view, #eef4fc);
        padding: 10px;
        border-radius: 5px;
    }

.EditDocument__split-view-selector label {
    font-size: 20px;
}
    
    .EditDocument__view-document-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 50px;
    }
    
    .EditDocument__close-btn {
        background: none;
        border: none;
        font-size: 18px;
        cursor: pointer;
    }
    
    .EditDocument__split-view-selector {
        margin-bottom: 10px;
    }
    
    @media (max-width: 800px) {
        .EditDocument__edit-document-container {
            flex-direction: column;
        }
    
        .EditDocument__editor-section,
        .EditDocument__view-document-section {
            flex: 1;
            width: 100%;
        }
    
        .EditDocument__editor-section.split {
            flex: 1;
        }

        .EditDocument__split-view-selector label {
            font-size: 12.5px;
        }
    }
    