.Help__submit-button {
        color: var(--help-submit-button-font-color, white);
        background-color: var(--help-submit-button-bg, #090e16);
        outline: none;
        border: 0;
        padding:10px 20px;
        text-transform:uppercase;
        border-radius:2px;
        margin-top: 100px;
        cursor:pointer;
        position:relative;
}

.Help__support-ticket-button {
        color: var(--help-submit-button-font-color, white);
        background-color: var(--help-submit-button-bg, #090e16);
        outline: none;
        border: 0;
        padding:10px 20px;
        text-transform:uppercase;
        border-radius:2px;
        cursor:pointer;
        position:relative;
}

@media (max-width: 600px) {
        .Help__shorter-margin-top {
                margin-top: 50px;
        }
}