.CreateWalkthrough__document-order-div {
        display: flex;
        flex-direction: column;
        margin-bottom: 70px;
}

.CreateWalkthrough__tutorial-cover-helper-text {
        margin-top: 100px;
}

.CreateWalkthrough__available-documents {
        margin-top: 5px;
}

.CreateWalkthrough__add-document-button {
        margin: 5px;
        outline: none;
        border: 0;
        background-color: var(--bg-button, #090e16);
        color: var(--btn-font-color, white);
        border-radius: 2px;
}

.CreateWalkthrough__selected-documents {
        margin-top: 5px;
}

.CreateWalkthrough__selected-document-item {
        margin-top: 5px;
        margin-bottom: 5px;
}

.CreateWalkthrough__remove-document-button {
        outline: none;
        border: 0;
        background-color: var(--bg-button, #090e16);
        color: var(--btn-font-color, white);
        border-radius: 2px;
        margin-left: 5px;
}

.CreateWalkthrough__move-up-button {
        outline: none;
        border: 0;
        background-color: var(--bg-button, #090e16);
        color: var(--btn-font-color, white);
        border-radius: 2px;
        margin-left: 5px;
}

.CreateWalkthrough__move-down-button {
        outline: none;
        border: 0;
        background-color: var(--bg-button, #090e16);
        color: var(--btn-font-color, white);
        border-radius: 2px;
        margin-left: 5px;
}

.CreateWalkthrough__submit-button {
        color: var(--submit-button-color, white);
        background-color: var(--bg-submit-button, #090e16);
        outline: none;
        border: 0;
        padding:10px 20px;
        text-transform:uppercase;
        margin-top:50px;
        border-radius:2px;
        cursor:pointer;
        position:relative;
}

@media (max-width: 600px) {
        .CreateWalkthrough__submit-button {
                margin-top: 50px;
        }
}