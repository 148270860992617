.SupportTicket__form {
        width: 100%;
        background-color: var(--bg-support-ticket-form, #090e16);
        border-radius: 5px;
        text-align: center;
        align-items: center;
        color: white;
        padding: 50px;
        margin-top: 50px;
        display: flex;
        flex-direction: column;
        margin-bottom: 50px;
}

.SupportTicket__input-container {
        position: relative;
        margin-bottom: 50px;
        width: 70%;
}

.SupportTicket__input {
        border-radius: 0;
        border: none;         
        border-bottom: 1px solid white; 
        color: #090E16;     
        padding-left: 20px;
        padding-right: 0;
        padding-bottom: 5px;
        padding-top: 5px;
        outline: none;
        font-size: 20px;
        margin-top: 30px;
}

.SupportTicket__input::placeholder {
        color: white;
        transition: color 0.3s ease;
}

.SupportTicket__input:focus::placeholder {
        color: transparent;
}

.SupportTicket__input:focus {
        outline: none;
        border: 0;
        border-bottom: 1px solid white;
}

.SupportTicket__input-container label {
        position:absolute;
        top: 0px;
        left: 0px;
        font-size:16px;
        color:var(--support-ticket-input, #fff);	
        pointer-events:none;
        transition: all 0.2s ease-in-out;
}

.SupportTicket__input-container input {
        border:0;
        border-bottom:1px solid var(--support-ticket-input, #555);  
        background:transparent;
        width:100%;
        padding:8px 0 5px 0;
        font-size:16px;
        color:#fff;
}

.SupportTicket__input-container input:focus {
        border:none;	
        outline:none;
        border-bottom:1px solid var(--support-ticket-input-focus, #6b9ce7);	
}

.SupportTicket__btn {
        color:#090E16;
        background-color: #6b9ce7;
        outline: none;
        border: 0;
        padding:10px 20px;
        text-transform:uppercase;
        margin-top:50px;
        border-radius:2px;
        cursor:pointer;
        position:relative;
}

.SupportTicket__input-container input:focus ~ label,
.SupportTicket__input-container input:valid ~ label{
        top: -12px;
        font-size: 12px;
}

.notification {
        background-color: #090E16;
        position: fixed;
        width: 40%;
        top: -900px;
        left: 0;
        right: 0;
        padding: 10px;
        text-align: center;
        text-overflow: ellipsis;
        border-radius: 5px;
        color: white;
        margin: auto;
}

.SupportTicket__file-upload-input {
        background-color: var(--bg-file-input-color, white) !important;
        padding: 12px;
        border: 0;
        border-radius: 25px;
        color: var(--file-input-color, #090E16);
}

.SupportTicket__text-area-input-container {
        position: relative;
        margin-bottom: 50px;
        width: 70%;
}

.SupportTicket__text-area-input-container textarea {
        width: 100%;
        padding: 10px;
        resize: none;
        background-color: var(--bg-text-area, white);
        border: 0.5px solid var(--text-area-border, white);
        border-radius: 0;
}

.SupportTicket__text-area-input-container textarea:focus {
        outline: none;
}

.SupportTicket__text-area-input-container label {
        font-size:16px;
        color: var(--text-area-label-color, #fff);
        pointer-events:none;
        text-align: left;
        display: flex;
        padding-bottom: 12px;
}

.SupportTicket__file-upload-input-container {
        margin-bottom: 40px;
}

.SupportTicket__h2 {
        margin-bottom: 50px;
        color: var(--support-ticket-input, white);
}

@media (max-width: 600px) {
        .SupportTicket__form {
                padding: 0;
                padding-top: 50px;
                padding-bottom: 50px;
        }
}