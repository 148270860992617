.Comment__div {
        background-color: #090E16;
        color: white;
        border-radius: 5px;
        padding: 10px;
        margin-top: 25vh;
}

.Comment__comment-area {
        outline: 1px solid white;
        border-radius: 5px;
        padding: 10px;
        white-space: pre-wrap;
        margin: 10px;
}

.Comment__reply {
        outline: 1px dashed white !important;
}

.Comment__plus-sign {
        font-size: clamp(0.75rem, 1vw, 1rem);
        cursor: pointer;
}

.Comment__comment-text-area {
        width: 100%;
        margin-top: 20px;
        padding: 10px;
        resize: none;
        border-radius: 0;
        font-size: clamp(0.75rem, 1vw, 1rem);
}

.Comment__submit-button {
        background-color: white !important;
        padding: 5px;
        border: 0;
        border-radius: 5px;
        font-size: clamp(0.75rem, 1vw, 1rem);
        color: #090E16;
}

@media (max-width: 600px) {
        .Comment__div {
                width: calc(100% + 85px);
        }
}