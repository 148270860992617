.Walkthrough__div {
        display: flex;
        min-height: 100vh;
        overflow: hidden;
        background-color: var(--bg-color, white);
}

.Walkthrough__main-content {
        flex-grow: 1; 
        transition: margin-left 0.3s ease; 
        padding: 20px;
        padding-top: 40px;
        padding-right: 100px;
        margin-left: 80px;
        color: var(--font-color, #090e16);
        height: 95vh;
        overflow-y: auto;
}

@media (max-width: 600px) {
        .Walkthrough__main-content {
                padding-top: 12vh;
                margin-left: 0;
                padding-right: 110px;
                overflow-x: hidden;
        }
}