.Account__main-content {
        padding: 20px;
        padding-right: 100px;
        padding-top: 40px;
        padding-left: 100px;
        background-color: var(--main-content-bg-color, white);
        color: var(--main-content-color, #090e16);
        height: 87vh;
        overflow-y: auto;
}

.Account__edit-input {
        border: none;
        border-bottom: 1px solid var(--main-content-color, #090e16);
        border-radius: 0; 
        width: 35%;
        color: var(--main-content-color, #090e16);
        background-color: var(--main-content-bg-color, white);
}

.Account__edit-input::placeholder {
        color: var(--placeholder-color, #090e16);
}

.Account__input-custom-title {
        border: none;
        border-bottom: 1px solid var(--main-content-color, #090e16);
        border-radius: 0; 
        width: 20%;
        margin-right: 20px;
        color: var(--main-content-color, #090e16);
        background-color: var(--main-content-bg-color, white);
}

.Account__input-custom-title::placeholder {
        color: var(--placeholder-color, #090e16);
}

.Account__icons {
        font-size: 28px;
        margin-right: 20px;
        color: var(--main-content-color, #090e16);
}

.Account__link-divs {
        margin-top: 20px;
}

.Account__input {
        border-radius: 0;
        background: transparent;
        border: none;         
        border-bottom: 1px solid var(--main-content-color, #090e16); 
        color: var(--main-content-color, #090e16);
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0;
        padding-top: 0;
        outline: none;
        width: fit-content;
        font-size: 20px;
}

.Account__main-content label {
        font-size: clamp(1rem, 2vw, 1.5rem); 
        padding-right: 10px;
        padding-bottom: 16px;
}

.Account__main-content input {
        font-size: clamp(1rem, 2vw, 1.5rem); 
}

.Account__main-content select {
        font-size: clamp(1rem, 2vw, 1.5rem); 
        line-height: 1.6 !important;
}

.Account__title-section {
        background-color: var(--title-section-bg-color, #090e16);
        color: var(--title-color, white);
        padding-top: 50px;
        padding-bottom: 20px;
        padding-left: 100px;
        padding-right: 100px;
}

.Account__read-only-field {
        background-color: var(--read-only-field, lightgray);
        width: fit-content;
        padding: 5px;
}

.Account__timestamps {
        padding-top: 30px;
        padding-bottom: 30px;
}

.Account__subscriber-button {
        color:var(--main-content-bg-color, white);
        background-color: var(--bg-subscriber-button, #090e16);
        outline: none;
        border: 0;
        padding:10px 20px;
        text-transform:uppercase;
        border-radius:2px;
        cursor:pointer;
        position:relative;
}

@media (max-width: 1100px) {
        .Account__input-custom-title {
                width: 30%;
        }
        .Account__edit-input {
                width: 60%;
        }
}

@media (max-width: 900px) {
        .Account__input-custom-title {
                width: 30%;
                margin-bottom: 15px;
        }

        .Account__edit-input {
                width: 80%;
        }
}

@media (max-width: 600px) {
        .Account__main-content {
                padding: 30px;
                padding-top: 20px;
                color: var(--main-content-color, #090e16);
                max-height: 87vh;
                overflow-y: auto;
                margin-left: 0;
        }

        .Account__title-section {
                padding-left: 30px;
        }

        .Account__main-content input {
                font-size: clamp(1rem, 2vw, 1.5rem); 
                margin-bottom: 16px;
        }

        .Account__main-content label {
                font-size: clamp(1rem, 2vw, 1.5rem); 
                padding-right: 10px;
                padding-bottom: 0px;
        }

        .Account__main-content p{
                margin-bottom: 28px;
        }

        .Account__input-custom-title {
                width: 55%;
                margin-bottom: 15px;
        }

        .Account__edit-input {
                width: 80%;
        }
}