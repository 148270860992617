.Create__div {
        display: flex;
        min-height: 100vh;
        overflow: hidden;
        background-color: var(--bg-color, white);
}

.Create__ul {
        padding-top: 20px;
}
      
.sidebar {
        width: 0px;
        transition: width 0.3s ease;
        background-color: var(--bg-sidebar-color, #090e16);
        color: var(--a-color, white);
        height: 100vh;
        position: relative;
        flex-shrink: 0;
}

.Create__output {
        margin-top: 50px;
}
      
.sidebar.collapsed {
        width: 250px;
}
      
.toggle-button {
        background-color: var(--toggle-button-color, #5B92E5);
        color: white;
        border: none;
        padding: 10px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        border-radius: 50%; 
        position: absolute;
        top: 20px; 
        right: -55px; 
        transition: transform 0.3s ease;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

.sidebar button i {
        font-size: 18px;
}
      
.Create__main-content {
        flex-grow: 1; 
        transition: margin-left 0.3s ease; 
        padding: 20px;
        padding-right: 100px;
        padding-top: 40px;
        margin-left: 80px;
        color: var(--main-content-color, #090e16);
        height: 95vh;
        overflow-y: auto;
}

.sidebar.collapsed .toggle-button {
        right: -20px; 
}

.Create__input {
        border-radius: 0;
        background: transparent;
        border: none;         
        border-bottom: 1px solid var(--main-content-color, #090e16); 
        color: var(--main-content-color, #090e16);
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0;
        padding-top: 0;
        outline: none;
        width: fit-content;
        font-size: 20px;
}

.Create__input-short-field {
        border-radius: 0;
        background: transparent;
        border: none;         
        border-bottom: 1px solid var(--main-content-color, #090e16); 
        color: var(--main-content-color, #090e16);
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0;
        padding-top: 0;
        outline: none;
        width: 45%;
        font-size: 20px;
}

.Create__input-short-field::placeholder {
        color: var(--placeholder-color, #090e16);
}

.Create__input-full-field {
        border-radius: 0;
        background: transparent;
        border: none;         
        border-bottom: 1px solid var(--main-content-color, #090e16); 
        color: var(--main-content-color, #090e16);
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0;
        padding-top: 0;
        outline: none;
        width: 70%;
        font-size: 20px;
}

.Create__helper-text {
        font-size: clamp(0.8rem, 0.9vw, 0.9rem); 
}

.Create__form-group {
        display: flex;
        margin-top: 60px;
        margin-left: 5px;
}

.Create__form-group-text-area {
        display: flex;
        flex-direction: column;
        margin-top: 60px;
}

.Create__info-icon {
        font-size: clamp(0.8rem, 0.9vw, 0.9rem); 
        margin-right: 5px;
        margin-left: 5px;
        margin-top: 3px;
}

.Create__form-group-helper-text {
        margin-top: 10px;
        display: flex;
}

.Create__form-group label {
        margin-right: 12px;
        font-size: 20px;
}

.Create__form-group-text-area label {
        font-size: 20px;
        margin-bottom: 10px;
}

.Create__input-text-area {
        border: 0.5px solid var(--text-area-border, #090e16);
        background-color: var(--bg-text-area, white);
        border-radius: 3px;
        resize: none;
        padding: 10px;
}
    
.Create__input::placeholder {
        color: #090e16;
        transition: color 0.3s ease;
}
    
.Create__input:focus::placeholder {
        color: transparent;
}
    
.Create__input:focus {
        outline: none;
        border: 0;
        border-bottom: 1px solid #090e16;
}

.Create__switch {
        position: relative;
        display: inline-block;
        width: 50px;
        height: 24px;
        margin-left: 10px;
        margin-top: 2px;
}
      
.Create__switch input {
        opacity: 0;
        width: 0;
        height: 0;
}
      
.Create__slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0px;
        bottom: 0;
        background-color: var(--bg-slider-box, #ccc);
        transition: 0.4s;
        border-radius: 34px;
}
      
.Create__slider:before {
        position: absolute;
        content: "";
        height: 16px;
        width: 16px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        transition: 0.4s;
        border-radius: 50%;
}
      
input:checked + .Create__slider {
        background-color: var(--bg-slider, #090e16);
}
      
input:checked + .Create__slider:before {
        transform: translateX(26px);
}
      
.Create__slider.round {
        border-radius: 34px;
}
      
.Create__slider.round:before {
        border-radius: 50%;
}

.Create__email-list {
        margin-top: 10px;
}

.Create__email-item {
        background-color: var(--email-item-bg, #090e16);
        color: var(--email-item-color, white);
        padding: 5px 10px;
        border-radius: 5px;
        margin-bottom: 5px;
        width: fit-content;
}

.Create__email-input-container {
        display: flex;
        align-items: center;
        margin-top: 10px;
}

.Create__email-input {
        width: 200px;
        padding: 5px;
        color: var(--main-content-color, #090e16);
        margin-right: 10px;
}

.Create__email-input::placeholder {
        color: var(--main-content-color, #090e16);
}

.Create__select {
        color: var(--main-content-color, #090e16);
}

.Create__add-email-btn {
        background-color: var(--email-item-bg, #090e16);
        color: white;
        border: none;
        padding: 5px 10px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        width: 30px;
        height: 30px;
}

.Create__add-email-btn i {
        font-size: 16px;
}

.Create__editor-div {
        margin-right: 100px;
}

.Create__file-upload {
        color: var(--main-content-color, #090e16)
}

@media (max-width: 1024px) {
        .Create__input-short-field {
                width: 70%;
        }
}

@media (max-width: 600px) {
        .sidebar.collapsed {
                width: 100vw;
                z-index: 1001;
        }

        .sidebar.collapsed .toggle-button {
                right: 20px; 
        }

        .Create__main-content {
                padding: 20px;
                padding-top: 12vh;
                margin-left: 0;
        }

        .Create__input-short-field {
                width: 90%;
                font-size: 12.5px;
        }

        .Create__input-full-field {
                font-size: 12.5px;
                width: 90%;
        }

        .Create__form-group label {
                font-size: 12.5px;
        }
        
        .Create__form-group-text-area label {
                font-size: 12.5px;
        }

        .Create__helper-text {
                font-size: 10px;
        }

        .Create__switch {
                width: 40px;
                height: 20px;
        }

        input:checked + .Create__slider:before {
                transform: translateX(16px);
        }
              
        .Create__slider.round {
                border-radius: 34px;
        }
              
        .Create__slider.round:before {
                border-radius: 50%;
        }

        .Create__slider {
                bottom: -4px;
                top: -4px;
                margin-bottom: 5px;
        }

        .Create__editor-div {
                margin-right: 0;
        }

        .Create__email-item span{
                font-size: 12.5px;
                width: 150px;
                white-space: nowrap;
                overflow: hidden; 
                text-overflow: ellipsis;
        }

        .Create__email-item select {
                font-size: 12.5px;
        }

        .Create__email-input {
                font-size: 12.5px;
        }
}

/* Fullscreen Mode Styles */
.EditDocument__quill-toolbar {
    display: flex;
    justify-content: flex-end;
    /* padding: 5px; */
    /* background-color: var(--quill-toolbar-bg-color, #f3f3f3);
    border-bottom: 1px solid var(--quill-toolbar-border-color, #ccc); */
}

.EditDocument__fullscreen-btn {
    background: none;
    border: none;
    cursor: pointer;
    color: var(--main-content-color, #000);
    padding: 5px 10px;
    border-radius: 4px;
    font-size: 16px;
}

.EditDocument__fullscreen-btn:hover {
    background-color: rgba(0, 0, 0, 0.1);
}

.fullscreen-mode {
    position: fixed !important;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    background-color: var(--main-content-bg-color, #fff);
    /* padding: 20px; */
    box-sizing: border-box;
    overflow: auto;
}

.fullscreen-mode .EditDocument__quill-toolbar {
    position: sticky;
    top: 0;
    z-index: 1000;
}

.fullscreen-mode .ql-container {
    height: calc(100vh - 50px) !important;
    max-height: unset !important;
}

/* .fullscreen-mode .ql-editor {
    max-height: unset !important;
} */

/* Fullscreen button in Quill toolbar */
.ql-fullscreen {
  position: relative;
  width: 28px;
  height: 24px;
}

.ql-fullscreen:after {
  font-family: "Font Awesome 5 Free";
  content: "\f065";
  font-weight: 900;
  font-size: 14px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fullscreen-mode .ql-fullscreen:after {
  content: "\f066"; /* Compress icon */
}

/* Add this new class for the corner positioned fullscreen button */
.EditDocument__fullscreen-btn-corner {
    position: absolute;
    bottom: 20px;
    right: 20px;
    background-color: var(--fullscreen-btn-bg-color, #5B92E5);
    color: var(--fullscreen-btn-color, white);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 5;
    transition: background-color 0.3s ease;
}

.EditDocument__fullscreen-btn-corner:hover {
    background-color: var(--fullscreen-btn-bg-hover-color, #090e16);
}

/* Make sure the quill container has position relative for absolute positioning to work */
.EditDocument__quill-container {
    position: relative;
}

/* Remove borders and border-radius in fullscreen mode */
.fullscreen-mode .ql-container {
    border: none !important;
    border-radius: 0 !important;
}

.fullscreen-mode .ql-toolbar {
    border: none !important;
    border-radius: 0 !important;
}

.fullscreen-mode .ql-editor {
    border: none !important;
    border-radius: 0 !important;
}