.Header__navbar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: var(--header-bg-color, #090E16);
        padding-left: 10px;
        padding-right: 10px;
        height: 13vh;
}

.Header__navbar-buttons {
        display: flex;
        justify-content: flex-end;
        gap: 10px;
        margin-right: 30px;
}

.Header__navbar-menu-icon {
        display: none;
        flex-direction: column;
        cursor: pointer;
        z-index: 99;
        padding-right: 10px;
}

.Header__menu-line {
        width: 25px;
        height: 3px;
        background-color: white;
        margin: 3px;
}

.Header__buttons {
        border: 0 !important;
        border-radius: 2px;
        color: var(--btn-font-color, #090E16);
        background-color: var(--bg-color-buttons, #5B92E5);
        padding: 7px;
}

.Header__login-button {
        border: 0 !important;
        border-radius: 2px !important;
        background-color: green !important;
        color: white !important;
        padding: 7px;
}

.Header__logout-button {
        border: 0 !important;
        border-radius: 2px !important;
        background-color: var(--bg-color-auth-button, #fd6e6e) !important;
        color: white !important;
        padding: 7px;
}

@media screen and (max-width: 768px) {

        .Header__navbar {
                height: 16vh;
        }

        .Header__navbar-buttons {
                display: none;
        }

        .Header__navbar-buttons .button {
                width: 100%;
        }

        .Header__navbar-menu-icon {
                display: flex;
        }

        .Header__navbar-buttons.open {
                display: flex;
                flex-direction: column;
                align-items: stretch;
                justify-content: flex-start;
                position: fixed;
                width: 100vw;
                height: 100vh;
                top: 0;
                left: 0;
                right: 0;
                background-color: #090e16;
                padding: 150px 50px;
                gap: 50px;
        }

}