.Sidebar__icon-container {
        display: flex;
        justify-content: center;
        padding-top: 35px; 
        padding-bottom: 39px;
}

.Sidebar__icons {
        color: var(--icon-color, white);
        font-size: 24px;
        padding-bottom: 5px;
        padding-right: 5px;
        padding-left: 5px;
}

.Sidebar__underline {
        border-bottom: 1px solid var(--a-color, white);
}

#user-icon, #users-icon {
        transition: border-bottom-color 0.4s ease;
}
    
.Sidebar__ul ul {
        padding-left: 25px; 
}

.Sidebar__ul p {
        margin-top: 6px;
        margin-bottom: 6px !important;
}

.Sidebar__ul a {
        font-size: 18px !important;
        text-decoration: none;
        color: var(--a-color, white);
}

.Sidebar__search-button {
        background-color: var(--bg-btn-search, #5b92e5);
        border: 0;
        border-radius: 2px;
        color: var(--btn-color, white);
        padding: 8px;
}

.Sidebar__search-icon-container {
        display: flex;
        justify-content: center;
        margin-top: 20px;
        overflow: hidden;
}

.Sidebar__search-icon {
        margin-right: 5px;
}

.Sidebar__ul a:hover {
        color: var(--a-hover-color, #5b92e5);
        transition: color 0.4s ease;
}

.Sidebar__ul li {
        margin-bottom: 6px;
}

    .Sidebar__ul li button {
        font-size: 14px;
        padding: 0;
        margin: 0;
    }

.Sidebar__hide-content {
        overflow: hidden;
}

.Sidebar__create-button {
        position: absolute;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
        background-color: var(--bg-btn-search, green);
        color: var(--btn-color, white);
        border: none;
        padding: 10px 20px;
        border-radius: 2px;
        display: flex;
        align-items: center;
        cursor: pointer;
        font-size: 16px;
        margin-bottom: 32%;
}

.Sidebar__plus-sign {
        font-size: 14px !important;
}

.Sidebar__help {
        position: absolute;
        bottom: 10px;
        left: 50%;
        transform: translateX(-50%);
        font-size: 26px !important;
        display: flex;
        align-items: center;
        margin-bottom: 15%;
        color: var(--help-search-color, white);
}

.Sidebar__no-content {
        color: var(--a-color, white);
}

.Sidebar__icon-size {
        font-size: clamp(0.5rem, 0.7vw, 0.7rem) !important;  
}

.Sidebar__link {
        font-size: 18px !important; 
        line-height: 1.2 !important;
        margin-bottom: 0 !important;
        display: -webkit-box; /* Enable block formatting with WebKit box model */
        -webkit-line-clamp: 1; /* Limit to 1 line */
        -webkit-box-orient: vertical; /* Define box orientation */
        max-height: 1.2em; /* Adjust based on line-height */
        overflow: hidden;
        text-overflow: ellipsis;
        word-wrap: break-word;
        margin-right: 5px;
}

.Sidebar__ul {
        list-style-type: none;
        padding-left: 10px;
        scrollbar-width: none;
        -ms-overflow-style: none;
        max-height: 88vh;
        overflow-y: auto;
}

@media (max-height: 1300px) {
        .Sidebar__ul {
                max-height: calc(75vh - 25px);
                overflow-y: auto;
        }
}

@media (max-height: 1200px) {
        .Sidebar__ul {
                max-height: calc(75vh - 68px);
                overflow-y: auto;
        }
}

@media (max-height: 1100px) {
        .Sidebar__ul {
                max-height: calc(75vh - 90px);
                overflow-y: auto;
        }
}

@media (max-height: 1000px) {
        .Sidebar__ul {
                max-height: calc(75vh - 110px);
                overflow-y: auto;
        }
}

@media (max-height: 900px) {
        .Sidebar__ul {
                max-height: calc(75vh - 120px);
                overflow-y: auto;
        }
}

@media (max-height: 800px) {
        .Sidebar__ul {
                max-height: calc(75vh - 140px);
                overflow-y: auto;
        }
}

@media (max-height: 700px) {
        .Sidebar__ul {
                max-height: calc(75vh - 170px);
                overflow-y: auto;
        }
}

@media (max-height: 600px) {
        .Sidebar__ul {
                max-height: calc(75vh - 190px);
                overflow-y: auto;
        }
}

@media (max-height: 500px) {
        .Sidebar__ul {
                max-height: calc(75vh - 220px);
                overflow-y: auto;
        }
}

@media (max-width: 600px) {

        .Sidebar__create-button {
                margin-bottom: 70px;
        }

        .Sidebar__help {
                margin-bottom: 30px;
        }

        .Sidebar__icon-size {
                font-size: 14px !important;  
        }

}