@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

body {
        margin: 0;
        font-family: 'Poppins', sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
}

code {
        font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

h1 {
        font-size: clamp(1.75rem, 4vw + 0.75rem, 3.5rem);
}

h1.large {
        font-size: clamp(2.5rem, 5.5vw + 1rem, 4.5rem);
}
      
h2 {
        font-size: clamp(1.5rem, 3.5vw + 0.5rem, 3rem);
}

h3 {
        font-size: clamp(1.25rem, 3vw + 0.25rem, 2.5rem);
}

h3.light {
        font-weight: 100;
}

h4 {
        font-size: clamp(1.125rem, 2.5vw, 2.25rem);
}

h4.light {
        font-weight: 100;
}

h5 {
        font-size: clamp(0.95rem, 2vw, 1.75rem);
}

h5.light {
        font-weight: 100;
}

h6 {
        font-size: clamp(0.85rem, 1.5vw, 1.5rem);
}

p.large {
        font-size: clamp(1.25rem, 2.5vw, 1.75rem);
        line-height: 1.6;
}

p.medium {
        font-size: clamp(1rem, 2vw, 1.5rem); 
        line-height: 1.6;
}

p {
        font-size: clamp(1rem, 2vw, 1.5rem); 
        line-height: 1.6 !important;
}

p.small {
        font-size: clamp(0.875rem, 1.5vw, 1.25rem);
        line-height: 1.6;
}

p.extra-small {
        font-size: clamp(0.75rem, 1vw, 1rem); 
        line-height: 1.6;
}

.semi-bold {
        font-weight: 500;
}

.submit-button {
        color: var(--submit-button-color, white);
        background-color: var(--bg-submit-button, #090e16);
        outline: none;
        border: 0;
        padding:10px 20px;
        text-transform:uppercase;
        margin-top:100px;
        border-radius:2px;
        cursor:pointer;
        position:relative;
}

option:disabled {
        color: gray;
}
      
.loading-animation-div {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #090e16;
        height: 100vh;
}

.loading-animation-icon {
        font-size: 50px;
        color: white;
        margin-bottom: 75px;
}

.ql-editor-styling {
        padding: 0 !important;
        overflow-y: visible !important;
        height: auto !important;
}

.ql-syntax {
        background-color: #090e16;
        margin-top: 10px !important;
        margin-bottom: 10px !important;
        padding: 10px !important;
        border-radius: 5px;
        color: white;
}

.ql-editor ul[data-checked="false"] li::before {
        content: '☐';
        color: #090e16; 
        font-size: clamp(0.875rem, 1.5vw, 1.25rem);
        cursor: default !important;
}

.ql-editor ul[data-checked="true"] li::before {
        content: '☑';
        color: #090e16; 
        font-size: clamp(0.875rem, 1.5vw, 1.25rem);
        cursor: default !important;
}

code.small {
        font-size: clamp(0.875rem, 1.5vw, 1.25rem);
        line-height: 1.6;
}

code.extra-small {
        font-size: clamp(0.75rem, 1vw, 1rem); 
        line-height: 1.6;
}

code.medium {
        font-size: clamp(1rem, 2vw, 1.5rem); 
        line-height: 1.6;
}

code.large {
        font-size: clamp(1.25rem, 2.5vw, 1.75rem);
        line-height: 1.6;
}

code {
        background-color: #090e16;
        color: white;
        border-radius: 5px;
        padding: 3px;
}

blockquote {
        border-left: 5px solid var(--blockquote-border-color, #090e16);
        background-color: var(--blockquote-bg-color, #dee9f9); 
        padding: 10px !important;
        font-style: italic;
        font-size: clamp(0.75rem, 1vw, 1rem); 
        line-height: 1.6;
}

.ql-multi-line-blockquote::before {
        font-family: monospace;
        font-size: 12px;
        content: '"."';
}      

.multi-line-blockquote {
        border-left: 4px solid #5B92E5;
        margin: 1em 0;
        padding-left: 1em;
        color: #555;
        font-style: italic;
        /* white-space: pre-wrap; */
}      

.ql-code-block::before {
        padding-left: 1em;
}

.ql-container.ql-snow {
        border: 1px solid var(--quill-container-border-color, #090e16) !important;
        border-radius: 0px 0px 5px 5px;
        background: var(--quill-container-bg-color, white);
}

.ql-toolbar.ql-snow {
        border: 1px solid var(--quill-toolbar-border-color, #090e16) !important;
        background-color: var(--quill-toolbar-bg-color, #090e16);
        border-bottom: none !important;
        border-radius: 5px 5px 0px 0px;
}

.ql-fill {
        fill: var(--quill-btn-color, white) !important;
}

.ql-picker {
        color: var(--quill-btn-color, white) !important;
}

.ql-stroke {
        color: var(--quill-btn-color, white) !important;
        stroke: var(--quill-btn-color, white) !important;
}

/* .ql-snow svg {
        color: white !important;
}
.ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
        color: var(--quill-btn-color, white) !important;
} */

/* .ql-picker-label {
        border: 0.1px solid var(--quill-btn-color, white) !important;
} */

.ql-picker-options {
        color: var(--quill-picker-options-color, #090e16) !important;
}

@media (max-width: 600px) {
        .submit-button {
                margin-top: 225px;
        }
}