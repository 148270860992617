.ql-size-large {
        font-size: clamp(1.25rem, 2.5vw, 1.75rem);
        line-height: 1.6;
}

.ql-size-small {
        font-size: clamp(0.875rem, 1.5vw, 1.25rem);
        line-height: 1.6;
}

.ql-inline-code::before {
        content: '<...>';
        font-family: monospace;
        color: var(--quill-btn-color, white) !important;
        font-size: 12px;
}

.ql-editor li {
        margin-bottom: 3px !important;
}

.ql-editor img {
        max-width: 100%; 
        height: auto;
}

h3 span.ql-size-small {
        font-size: clamp(1.2rem, 3vw + 0.2rem, 2.2rem);
}

h3 span.ql-size-large {
        font-size: clamp(1.3rem, 3vw + 0.3rem, 2.8rem);
}

.Document__document-views-text {
        padding-top: 30px;
}

h4 span.ql-size-small {
        font-size: clamp(1.125rem, 2.5vw, 2.25rem);
}

h4 span.ql-size-large {
        font-size: clamp(1.125rem, 2.5vw, 2.25rem);
}

.Document__floating-div {
        position: fixed;
        right: 0px;    
        background-color: var(--bg-floating-div, #090e16);
        padding: 15px;
        z-index: 1000; 
        display: flex;
        align-items: center;
        color: var(--font-color-icons, white);
}

.Document__icon-padding {
        padding-right: 5px;
        padding-left: 5px;
}

.Document__a {
        color: var(--font-color-icons, white);
}

.Document__icon-separator {
        border-left: 1px solid white; 
        height: 25px; 
        margin: 0 8px; 
}

.Document__overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;
}

.Document__popup {
        background-color: var(--bg-color, #fff);
        border-radius: 5px;
        width: 60%;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
        z-index: 1001;
}

.Document__popup h2 {
        background-color: var(--bg-floating-div, #090e16);
        border-radius: 5px 5px 0 0;
        padding: 20px;
        color: var(--h2-color, white);
        margin-bottom: 0;
}

.Document__popup p {
        padding: 20px;
        margin-bottom: 0 !important;
}

.Document__popup label {
        margin-left: 40px;
}

.Document__modal-close-button {
        background-color: var(--bg-floating-div, #090e16);
        color: white;
        border: none;
        margin: 20px;
        padding: 8px;
        border-radius: 2px;
}

.Document__shareable-link-container {
        position: relative;
        display: inline-block;
        width: 95%;
        /* border: 1px solid #ccc; */
        /* padding: 4px; */
        /* box-sizing: border-box; */
        white-space: nowrap;
        overflow-x: auto;
        overflow-y: hidden;
}

.Document__modal-submit-button {
        background-color: var(--bg-floating-div, #090e16);
        color: var(--font-color, white);
        border: none;
        margin: 20px;
        padding: 8px;
        border-radius: 2px;
}

.Document__share-link {
        display: inline-block;
        width: 99%;
        white-space: nowrap;
        overflow-x: auto; 
        overflow-y: hidden;
        border: 1px solid #ccc;
        padding: 4px; 
}

.Document__copy-icon {
        position: absolute;
        right: 1px; 
        /* left: 98%; */
        top: 43%;
        transform: translateY(-50%);
        font-size: 20px;
        color: var(--bg-floating-div, #090e16);
        cursor: pointer;
}

@media (max-width: 600px) {
        .Document__div {
                right: 0;
                width: calc(100% + 85px);
        }

        .Document__popup {
                width: 95%;
        }

        .Document__copy-icon {
                font-size: 14px;
                top: 45%;
        }
}