.DocumentationHomeComponent__loading-animation-div {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--bg-main-container, white);
        height: 100vh;
}

.DocumentationHomeComponent__loading-animation-icon {
        font-size: 50px;
        color: var(--font-color, #090e16);
        margin-bottom: 75px;
}