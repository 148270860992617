@keyframes fadeIn {
        from {
                opacity: 0;
        }
        to {
                opacity: 1;
        }
}

.HomePage__main-container {
        min-height: 87vh;
        background-color: var(--bg-color,#090e16);
        padding-right: 10px;
        padding-left: 10px;
        padding-bottom: 100px;
}

.HomePage__middle-divs {
        display: flex;
        gap: 30px;
        margin-top: 50px;
}

.HomePage__create-container {
        height: 265px;
        width: 265px;
        background-color: var(--bg-color-create-div, white);
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        cursor: pointer;
        transition: border-radius 0.3s ease-in-out; 
}

.HomePage__create-container-register {
        opacity: 0; /* Initially hidden */
        animation: fadeIn 1.5s ease-in-out forwards;
        animation-delay: 1.5s; /* Delay before fade-in starts */
        height: 265px;
        width: 265px;
        background-color: var(--bg-color-create-div, white);
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        cursor: pointer;
        transition: border-radius 0.3s ease-in-out; 
}

.HomePage__create-container-register-youtube {
        opacity: 0; /* Initially hidden */
        animation: fadeIn 1.5s ease-in-out forwards;
        animation-delay: 1.5s; 
        height: 265px;
        width: 265px;
        background-color: red;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        cursor: pointer;
        transition: border-radius 0.3s ease-in-out; 
}

.HomePage__create-container:hover {
        border-radius: 0;
}

.HomePage__text {
        color: var(--text-color, white);
}

.HomePage__text-register {
        opacity: 0; /* Initially hidden */
        animation: fadeIn 1.5s ease-in-out forwards;
        animation-delay: 1.5s; /* Delay before fade-in starts */
        color: var(--text-color, white);
}

.HomePage__text-info {
        opacity: 0; /* Initially hidden */
        animation: fadeIn 0.2s ease-in-out forwards;
        animation-delay: 0.2s; /* Delay before fade-in starts */
        color: var(--text-color, white);
}

.HomePage__text-info2 {
        opacity: 0; /* Initially hidden */
        animation: fadeIn 0.8s ease-in-out forwards;
        animation-delay: 0.8s; /* Delay before fade-in starts */
        color: var(--text-color, white);
}

.HomePage__create-div-text {
        color: var(--create-div-font-color, #090e16);
}

.HomePage__text-alternate {
        color: var(--text-color-alternate, #5B92E5);
}

.HomePage__text-alternate-register {
        opacity: 0; /* Initially hidden */
        animation: fadeIn 1.5s ease-in-out forwards;
        color: var(--text-color-alternate, #5B92E5);
}

.HomePage__plus-sign {
        font-size: clamp(1.25rem, 3vw + 0.25rem, 2.5rem);
        color: var(--bg-color-icon, green);
        margin-top: 10px;
        font-weight: 100;
}

.HomePage__register-icon {
        font-size: clamp(1.25rem, 3vw + 0.25rem, 2.5rem);
        margin-top: 10px;
}

@media (max-width: 700px) {
        .HomePage__main-container {
                padding-top: 30px;
                padding-right: 20px;
                padding-left: 20px;
                height: 84vh;
                overflow: hidden;
        }

        .HomePage__middle-divs {
                display: flex;
                flex-direction: column;
        }

        .HomePage__create-container {
                height: 100px;
        }

        .HomePage__create-container-register {
                height: 100px;
        }

        .HomePage__create-container-register-youtube {
                height: 100px;
        }
}

@media (max-height: 830px) {
        p.large {
                font-size: clamp(1rem, 2vw, 1.5rem); 
                line-height: 1.6;
        }

        .HomePage__middle-divs {
                margin-top: 5px;
        }

        .HomePage__create-container-register {
                height: 80px;
        }

        .HomePage__create-container-register-youtube {
                height: 80px;
        }

        .HomePage__register-icon {
                margin-top: 0;
        }
}